import React from 'react';
import {Header} from '../../components/Navbar';
import {FormUpload} from '../../components/FormUpload';
import 'bootstrap/dist/css/bootstrap.min.css';

export function UploadDonation() {
  return (
    <>
      <Header />
      <FormUpload />
    </>
  );
}
