import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

function patchFilterFactory(filterFactory, onFilteredData) {
  return (...args) => {
    const { createContext, options } = filterFactory(...args)
    return {
      createContext: (...args) => {
        const { Provider: BaseProvider, Consumer } = createContext(...args)
        const Provider = class FilterProvider extends BaseProvider {
          componentDidUpdate() {
            onFilteredData(this.data)
          }
        }
        return { Provider, Consumer } 
      },
      options
    }
  }
}

export default patchFilterFactory