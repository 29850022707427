import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./index.css";

export function TitleImage({ title }) {
  return (
    <div
      style={{
        position: "relative",
        textAlign: "center",
        marginTop: "30px",
      }}
    >
      {" "}
      <LazyLoadImage
        src="https://images.unsplash.com/photo-1499244571948-7ccddb3583f1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1789&q=80"
        style={{
          width: "100%",
          height: "240px",
          filter: "brightness(60%)",
        }}
      />
      <div
        className="message"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          color: "#b1b1b1",
          fontWeight: "bold",
          fontSize: "38px",
          width: "60%",
          fontFamily: "Open Sans",
        }}
      >
        {title}
      </div>
    </div>
  );
}
