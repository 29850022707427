import {createTheme} from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#F79118',
      light: '#fa9c2d',
      dark: '#e68410',
    },
    /* secondary: {
      main: '#002169',
      light: '#082361',
      dark: '#011645',
    },
    turqoise: {
      main: '#6BDAD5',
      light: '#55C9C3',
      dark: '#34807B',
    },
    error: {
      main: '#CB3A31',
      light: '#BD251C',
      dark: '#731912',
    },
    warning: {
      main: '#CD7B2E',
      light: '#BF6919',
      dark: '#734011',
    },
    success: {
      main: '#43936C',
      light: '#367A59',
      dark: '#20573D',
    },
    info: {
      main: '#3267E3',
      light: '#114CD6',
      dark: '#11317D',
    }, */
    grey: {
      100: '#DCDCDC',
      200: '#D6D6D5',
      300: '#CACAC9',
      400: '#B0B1AE',
      500: '#91938F',
      600: '#6E6F6A',
      700: '#5D5E58',
      800: '#40423A',
      900: '#090C02',
    },
    text: {
      primary: '#090C02',
    },
  },
});
