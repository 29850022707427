import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

export function ModalSort({
  onHide,
  show,
  sortList,
  file_type,
  setDataKeys
}) {

  const SortableItem = SortableElement(({value}) => { 
    return (
      <li
        style={{
          font: '200 20px/1.5 Helvetica, Verdana, sans-serif',
          borderBottom: '1px solid #ccc',
          zIndex: 99999999,
          listStyleType: 'none',
        }}
      >
        {value.text}
      </li>
    )
  });

  const SortableList = SortableContainer(({items}) => {
    return (
      <ul 
        style={{
          listStyleType: 'none',
          margin: 0,
          padding: 0
        }}
        >
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value}/>
        ))}
      </ul>
    );
  }); 

  function onSortEnd({oldIndex, newIndex}){
    const newData = arrayMoveImmutable(sortList.data, oldIndex, newIndex)
    let cacheData = []
    newData.forEach(x => {
      if (x.dataField) cacheData.push(x.dataField)}
    )
    setDataKeys(cacheData)
    localStorage.setItem(`${file_type}-column-list`,JSON.stringify(cacheData))
    sortList.dataSetter(newData)
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      autoFocus={false}
      enforceFocus={false}
      backdrop={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Reorder Table Columns</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SortableList items={sortList.data.filter(x => x.text !== 'Delete')} onSortEnd={onSortEnd} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}