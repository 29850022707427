import React from 'react';
import {Header} from '../../components/Navbar';
import {InfoDetail} from '../../components/InfoDetail/InfoDetail';
import 'bootstrap/dist/css/bootstrap.min.css';

export function DonationDetailPage(props) {
  return (
    <>
      <Header />
      <InfoDetail />
    </>
  );
}
